import React, { Component } from 'react';
import { Container, Row, Col, Image, Alert } from 'react-bootstrap';
import './App.css';
import logo from './logo.svg';


export default class Notifications extends Component{
  render(){
      return(
        <Container fluid className="App">
          <Row className="text-center justify-content-center App-header">
            <Col md={3}>
              <Image src={logo} className="App-logo-content" />
                <Alert variant={this.props.variant} >{this.props.text}</Alert>
            </Col>
          </Row>
        </Container>
      );
  }
}
