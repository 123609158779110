import React from 'react';

import Main from './Main';

import firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from './firebaseConfig';

import ResetPassword from './ResetPassword';
import VerifyEmail from './VerifyEmail';

function App() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  // Get the action to complete.
  let mode = params.get('mode');
  // Get the one-time code from the query parameter.
  let actionCode = params.get('oobCode');
  // (Optional) Get the continue URL from the query parameter if available.
  let continueUrl = params.get('continueUrl');
  // (Optional) Get the language code if available.
  let lang = params.get('lang') || 'en';

  let firebaseApp;

  if (!firebase.apps.length) {
    firebaseApp = firebase.initializeApp(firebaseConfig);
  } else {
     firebaseApp = firebase.app(); // if already initialized, use that one
  }


  const auth = firebaseApp.auth();

  switch (mode) {
    case 'resetPassword':
      return(<ResetPassword auth={auth} actionCode={actionCode} continueUrl={continueUrl} lang={lang} />);
    case 'verifyEmail':
      return(<VerifyEmail auth={auth} actionCode={actionCode} continueUrl={continueUrl} lang={lang} />)
    default:
      return (
        <Main />
      );
  }


}

export default App;
