import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Image, Alert, Spinner } from 'react-bootstrap';
import './App.css';
import logo from './logo.svg';

import Notifications from './Notifications'

export default class ResetPassword extends Component{

  constructor(){
    super();
    this.state = {
      show : false,
      password : '',
      repassword : '',
      disabled: true,
      alertText: '',
      notifications: '',
      success: false,
      submit: false,
      error: false,
      email: ''
    }
  }

  componentDidMount(){
    let self = this;
    this.props.auth.verifyPasswordResetCode(this.props.actionCode).then(function(email) {
      self.setState({email:email});
    }).catch(function(error) {
      self.setState({error:true, notifications: error.message});
    });
  }


  handleRePassword = (event) => {
    this.setState({repassword: event.target.value});
    this.handleAlert(this.state.password, event.target.value);
  }

  handlePassword = (event) => {
    this.setState({password: event.target.value});
    this.handleAlert(event.target.value, this.state.repassword);
  }

  handleSubmit = (event) => {
    let newPassword = this.state.password;
    let self = this;
    this.setState({submit:true, disabled: true});
    this.props.auth.confirmPasswordReset(this.props.actionCode, newPassword).then(function(resp) {
      self.setState({notifications: "Password changed. You can now sign in with your new password.", success: true, disabled:false });
    }).catch(function(error) {
      self.setState({show:true, alertText: error.message, success: false, disabled:false, submit: false});
    });
  }

  handleAlert(password,repassword){
    if(password.length < 6 || repassword.length < 6){
      this.setState({show:true, disabled: true, alertText: 'Password at least 6 characters!'});
    } else if(password !== repassword){
      this.setState({show:true, disabled: true,  alertText: 'Password is not same!'});
    } else {
      this.setState({show:false, disabled: false});
    }
  }


  formPassword(){
    return(
      <Container fluid className="App">
        <Row className="text-center justify-content-center App-header">
          <Col md={3}>
            <Image src={logo} className="App-logo-content" />
            <h1>Reset Password</h1>
            <p>for {this.state.email}</p>
            <Form>
              <Form.Group controlId="formBasicPassword">
                <Form.Control type="password" placeholder="New Password" onChange={this.handlePassword}/>
              </Form.Group>

              <Form.Group controlId="formBasicRePassword">
                <Form.Control type="password" placeholder="Re-Password" onChange={this.handleRePassword} />
              </Form.Group>
              {this.state.show  && <Alert variant="danger" >{this.state.alertText}</Alert> }
              <Button variant="primary" type="button" onClick={this.handleSubmit} disabled={this.state.disabled}>
              {this.state.submit ?
                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"/>: 'Submit' }
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }

  render(){
    if(this.state.success){
      return(<Notifications variant="success" text={this.state.notifications} />);
    } else if(this.state.error){
      return(<Notifications variant="danger" text={this.state.notifications} />);
    } else {
      return(this.formPassword());
    }
  }
}
