import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';


export default class Main extends Component{
  render(){
    return(
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>KOJO</h1>
          <p>
          Kasir Online Untuk Jualan Online/Offline
          </p>
          <a className="App-playstore" href="https://play.google.com/store/apps/details?id=com.podelz.kojo&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"/>
          </a>
        </header>
      </div>
    );
  }
}
