const firebaseConfig = {
  apiKey: "AIzaSyBF8hH8ifU_DD-U5KlHbruoPFZoqZQ2S14",
  authDomain: "kojo-c693a.firebaseapp.com",
  databaseURL: "https://kojo-c693a.firebaseio.com",
  projectId: "kojo-c693a",
  storageBucket: "kojo-c693a.appspot.com",
  messagingSenderId: "872288740074",
  appId: "1:872288740074:web:2679d1dc3678043b30ed93",
  measurementId: "G-FYT80CRLCT"
};
export default firebaseConfig;
