import React, { Component } from 'react';
import Notifications from './Notifications';

export default class VerifyEmail extends Component{
  constructor(){
    super();
    this.state = {
      error: false,
      notifications: ''
    }
  }

  componentDidMount(){
    this.props.auth.applyActionCode(this.props.actionCode).then((resp) => {
      this.setState({error:false, notifications: "Your email has been verified. You can now sign in with your new account."});
    }).catch((error) => {
      this.setState({error:true, notifications: error.message});
    });

  }

  render(){
    if(this.state.error){
      return(
        <Notifications variant="danger" text={this.state.notifications} />
      )
    } else {
      return(
        <Notifications variant="success" text={this.state.notifications} />
      )
    };
  }
}
